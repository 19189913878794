import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);
export const summitCountDown = () => {
  const starting_at = Date.parse($('#countdown_starting_at').attr('datetime'));
  const finishing_at = Date.parse($('#countdown_finishing_at').attr('datetime'));

  const congressDurationState = (starting_at, finishing_at) => {
    const date = new Date();
    const now = date.getTime();
    if ($('#conditionally_authenticate').length > 0) return 'between';

    switch(true) {
      case (starting_at <= now && finishing_at >= now):
        return 'between';
      case (now < starting_at):
        return 'before';
      case (now > finishing_at):
        return 'after';
    }
  }

  const countDown = (starting_at) => {
    const now = new Date().getTime();
    const distance = starting_at - now;

    let content = '';
    let days = Math.floor(distance / (1000 * 60 * 60 * 24));
    let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    let seconds = Math.floor((distance % (1000 * 60)) / 1000);

    if (days === 0 && hours === 0 && minutes < 5) {
      content = 'kilka minut';
    } else if (distance > 0) {
      if (hours < 10 && hours !== 0) {
        hours = '0' + hours
      }
      if (minutes < 10) {
        minutes = '0' + minutes
      }
      if (seconds < 10) {
        seconds = '0' + seconds
      }
      content = `
      <div class="flex flex-column justify-center items-center">
      <div class="days">${days} <span class="block mt-3 font-medium text-lg uppercase">${days === 1 ? 'dzień' : 'dni'}</span></div>
      <div class="colon">:</div>
      <div class="hours">${hours} <span class="block mt-3 font-medium text-lg uppercase">godz.</span></div>
      <div class="colon">:</div>
      <div class="minutes">${minutes} <span class="block mt-3 font-medium text-lg uppercase">min.</span></div>
      <div class="colon">:</div>
      <div class="seconds">${seconds} <span class="block mt-3 font-medium text-lg uppercase">sek.</span></div>
      </div>`;
    } else {
      content = '';
    }
    $('#countdown').html(content);
    return distance;
  }

  const summitInterval = setInterval(function run() {
    switch(congressDurationState(starting_at, finishing_at)) {
      case 'before':
        $('#player-countdown h5').text('Transmisja rozpocznie się za:');
        countDown(starting_at);
        break;
      case 'after':
        clearInterval(summitInterval);
        $('#player-countdown h5').addClass('pt-5').text('Wydarzenie się skończyło');
        $('#vimeo_player').remove();
        $('#player-wrapper').addClass('hidden');

        break;
      case 'between':
        clearInterval(summitInterval);
        $('#player-countdown').remove();
        $('#congress').removeClass('filter blur-md');
        $('#curtain').remove();
        $('#player-wrapper').removeClass('hidden');
        if ($('#vimeo_player').length === 0) {
          window.location.reload();
        }
        break;
    }
    return run;
  }(), 1000, starting_at, finishing_at);
}
