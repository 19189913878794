import LocalTime from 'local-time';

LocalTime.config.i18n['pl'] = {
    date: {
      dayNames: ["Niedziela", "Poniedziałek", "Wtorek", "Środa", "Czwartek", "Piątek", "Sobota"],
      abbrDayNames: ["Nd", "Pn", "Wt", "Śr", "Czw", "Pt", "Sb"],
      monthNames: ["stycznia", "lutego", "marca", "kwietnia", "maja", "czerwca", "lipca", "sierpnia", "września", "października", "listopada", "grudnia"],
      abbrMonthNames: ["Sty", "Lut", "Mar", "Kwi", "Maj", "Cze", "Lip", "Sie", "Wrz", "Paź", "Lis", "Gru"],
      yesterday: "wczoraj",
      today: "dziś",
      tomorrow: "jutro",
      on: "on {date}",
      formats: {
        "default": "%b %e, %Y",
        thisYear: "%b %e"
      }
    },
    time: {
      am: "am",
      pm: "pm",
      singular: "{time}",
      singularAn: "{time}",
      elapsed: "{time} temu",
      second: "sekundę",
      seconds: "sekund",
      minute: "minutę",
      minutes: "minut",
      hour: "godzinę",
      hours: "godzin",
      formats: {
        "default": "%l:%M%P"
      }
    },
    datetime: {
      at: "{date} {time}",
      formats: {
        "default": "%B %e, %Y, godzina %l:%M%P %Z"
      }
    }
};
LocalTime.config.locale = 'pl'
