require("@rails/ujs").start();

import 'bootstrap';
import 'jquery.easing';
import 'slick-carousel';
import hcSticky from 'hc-sticky';
import ahoy from 'ahoy.js';
import '../src/scroll-nav';
import '../src/jquery.checkall';
import '../src/helpers';
import '../src/professional_group';
import '../src/company';
import '../src/popover_preview';
import isMobile from '../src/isMobile';
import { summitCountDown } from '../src/countdown';
import carouselSettings from '../src/sponsor_carousels';
import checkNotice from '../src/check_notice';
import consumer from '../src/channels/consumer'
import { showTransmission, togglePassword } from '../src/utils';
import 'speakers_questions';
import Alpine from 'alpinejs';
import mdtoast from '@dmuy/toast';
import LocalTime from 'local-time';
import '../src/local_time_pl';
LocalTime.start()

window.$ = window.jQuery = $;
window.isMobile = isMobile;
window.mdtoast = mdtoast;
window.carouselSettings = carouselSettings;
window.consumer = consumer;
window.summitCountDown = summitCountDown;
window.showTransmission = showTransmission;
// stylesheets
import '../src/font-awesome.js';

const toggleDown = '<i class="fas fa-arrow-down"></i>';
const toggleUp = '<i class="fas fa-arrow-up"></i>';

const toggleArrow = elem => $(elem).html($('#description').is(':visible') ? toggleUp : toggleDown);

$(document).ready(() => {
  $('#toggle_description').click(event => {
    event.preventDefault();
    $('#description').slideToggle({
      easing: 'easeInExpo',
      duration: 1000,
      step: () => toggleSponsorBackground(),
      done: () => toggleArrow(event.currentTarget),
    });
  });
  $('#date').text(new Date().getTime());
  $('.checkall').checkall();

  togglePassword();

  if ($('#congress-days-buttons .congress-day-links').length) {
    $('#congress-days-buttons .congress-day-links').click(function (event) {
      event.preventDefault();
      const currentDay = $(this).data('congress-day');

      // toggle sponsors
      $('#all-sponsors .sponsors-by-day').fadeOut('1000', 'easeInOutExpo').hide();
      $('#partners-carousel .sponsors-by-day').fadeOut('1000', 'easeInOutExpo').hide();
      $(`#sponsors-${currentDay}`).fadeIn('1000', 'easeInOutExpo').show();
      $(`#sponsors-carousel-${currentDay}`).fadeIn('1000', 'easeInOutExpo').show();
      //
      //toggle days buttons
      $('#congress-days-buttons .congress-day-links.btn-primary').toggleClass('btn-primary').toggleClass('btn-outline-primary');
      $(`#congress_day_link-${currentDay}`).toggleClass('btn-primary').toggleClass('btn-outline-primary');
      //
      // //toggle agenda
      $('#all-appointments .appointments-by-day').fadeOut('1000', 'easeInOutExpo').hide();
      $(`#appointments-${currentDay}`).fadeIn('1000', 'easeInOutExpo').show();
    })
  }

  const minSponsorsAmountForSlider = 3;

  if ($('.logos').length > minSponsorsAmountForSlider) {
    $('.slide-track').slick({
      dots: false,
      draggable: false,
      arrows: false,
      infinite: true,
      speed: 500,
      autoplay: true,
      autookaySpeed: 3000,
      cssEase: 'linear',
      variableWidth: true
    });
  }
  if ($('#countdown').length) {
    summitCountDown()
  }

  if ($('.hcsticky').length && !isMobile()) {
    const contactForm = new hcSticky('.sponsor--contact-form', {
      stickTo: '.wrapper',
      top: 130,
    });
    const sidePromo = new hcSticky('aside.promotion', {
      stickTo: '#main-content',
      top: 130,
    });
  }

  if ($('#notice-container').length) {
    setInterval(checkNotice, 60000);
  }

  if ($('#landing-header').length && isMobile()) {
    $('#landing-header').css('background-image', 'none');
  }

  if (isMobile()) {
    $('body').addClass('mobile');
  }
});

$(document).on('ajax:send', '#new_congresses_sponsors_message', function (e) {
  Helpers.appendIndicator($(e.target));
});

$(document).on('ajax:success', '#new_congresses_sponsors_message', function (e) {
  var data = event.detail[0];
  $('#sponsor_message').html(data['html'])
});

$(document).on('ajax:error', '#new_congresses_sponsors_message', function (e) {
  Helpers.removeIndicator($(e.target))
});

$(document).on('ajax:send', '#congress_sponsor_send_attachments', function (e) {
  $(e.target).prop('disabled', true);
});

$(document).on('ajax:success', '#congress_sponsor_send_attachments', function (e) {
  var data = event.detail[0];
  $(e.target).prop('disabled', false);
  alert(data['result'])
});


$(document).on('click', 'a.sponsor-external-link', function (e) {
  var track_data = $(e.target).data('track').split('/')
  ahoy.track('sponsor_link_click', {
    link_id: parseInt(track_data[0]),
    sponsor_id: parseInt(track_data[1]),
    congress_slug: track_data[2],
    section_slug: track_data[3]
  });
});

$(document).on('click', 'a.sponsor-product-link', function (e) {
  var track_data = $(e.target).closest('a').data('track').split('/')
  ahoy.track('sponsor_product_click', {
    product_id: parseInt(track_data[0]),
    sponsor_id: parseInt(track_data[1]),
    congress_slug: track_data[2],
    section_slug: track_data[3],
    on_marketplace: (track_data[4] != undefined)
  });
});

$(document).on('click', '.trix-content a', function (e) {
  $(this).attr('target','_blank');
});

const toggleSponsorBackground = () =>
  $('#description-bg').css('height', $('#sponsor-description').outerHeight())

const images = require.context('../images', true)

window.Alpine = Alpine
if (typeof window !== 'undefined') {
  window.addEventListener('DOMContentLoaded', () => {
    window.Alpine.start()
  })
}
