import Rails from '@rails/ujs'

$(document).ready(() => {
  const taxIdInput = $('#user_company_attributes_tax_id, #congresses_users_register_company_tax_id, #congresses_users_insert_company_tax_id')
  const nameInput = $('#user_company_attributes_name, #congresses_users_register_company_name, #congresses_users_insert_company_name')
  const resultsBox = $('<div class="search_results"><ul></ul></div>')

  taxIdInput.parent().after(resultsBox)

  const listItem = ({ name, tax_id }) => {
    const item = $(`<li>${name}</li>`)

    item.on('click', () => {
      $(resultsBox).find('ul').html('')
      $(taxIdInput).val(tax_id)
      $(nameInput).val(name)
      $(nameInput).attr('readonly', true)
    })

    return item
  }

  taxIdInput.on('input', e => {
    const taxId = e.target.value.replace(/\D/g,'');
    $(e.target).val(taxId)
    $(nameInput).attr('readonly', false)

    if (taxId.length < 4) {
      return $(resultsBox).find('ul').html('');
    }

    Rails.ajax({
      url: `/api/companies?search=${taxId}`,
      type: 'get',
      success: data => {
        $(resultsBox).find('ul').html(data.map(listItem))

        if (data.length === 1 && data[0].tax_id === e.target.value) {
          $(e.target).on('blur', blur => {
            if (data[0].tax_id !== blur.target.value) return

            $(resultsBox).find('ul').html('');
            $(nameInput).val(data[0].name)
            $(nameInput).attr('readonly', true)
          })
        }
      }
    })
  });
});
