export const leadZero = number => number < 10 ? `0${number}` : number;

export const showTransmission = startingAt => new Date().getTime() < startingAt

export const togglePassword = () => {
  const visibilityToggle = $('.toggle-password');
  if (visibilityToggle.length === 0) return false

  const input = $('.toggle-password-wrapper input');
  let password = true;
  visibilityToggle.on('click', function() {
    if (password) {
      input.attr('type', 'text');
      visibilityToggle.removeClass('fa-eye')
      visibilityToggle.addClass('fa-eye-slash')
    } else {
      input.attr('type', 'password');
      visibilityToggle.removeClass('fa-eye-slash')
      visibilityToggle.addClass('fa-eye')
    }
    password = !password;
  });
}
